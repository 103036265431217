<template>
<v-row>
<v-col>
    <v-data-table
        :headers="headers"
        :items="ingresos"
        :items-per-page="1000"
        single-expand
        :expanded.sync="expanded"
        dense
        item-key="name"
        :show-expand=false
        :loading = loadingTable
        :hide-default-footer = true
        class="elevation-1"
    >
         
        <template v-slot:top>
            <v-toolbar flat>
                <v-form ref="form_toolbar_search">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-menu>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="pt-5"
                                            v-model="dateRangeText"
                                            label="Intervalo de fechas"
                                            persistent-hint
                                            readonly
                                            append-icon="mdi-calendar-multiple"
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable        
											 
											                
                                            click:clear="dates=''"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        range
                                        v-model="dates"
                                        v-on:change="periodo=''"  
                                        no-title
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            
                             <v-col cols="12" md="6">
                                <v-autocomplete
                                    v-model=periodo
                                    class="pt-5"
                                    label="Mes contable"
                                    :items=periodos
                                    item-value="custom_time_period_id"
                                    item-text="period_name"
                                    clearable
                                    click:clear="accountingMonth =''"
                                ></v-autocomplete>
                            </v-col>
                            
                    </v-row>
                    </v-col>
                </v-form>
                <v-btn small color="primary" @click="generarReporte">
                    <v-icon>mdi-magnify</v-icon> Buscar
                </v-btn>
                <v-btn small color="primary" @click="descargarReporte" class="ml-md-1">
                    <v-icon>mdi-file-excel</v-icon> Descargar
                </v-btn>
            </v-toolbar>
             <template >
    			<v-toolbar flat>Ingresos</v-toolbar>
    		</template>
        </template>  
        
    	<template v-slot:footer>
    		<v-row>
    		<v-col class="col-md-10 text-right">
    			<strong> Total:</strong>
    		</v-col>
		    <v-col class="col-md-2 text-right pr-7">
		    	<strong>{{totalIngreso.toFixed(2)}}</strong>
		    </v-col>
		    
		    
		    </v-row> 
		</template>   
    </v-data-table>
    
    <v-data-table
        :headers="headers"
        :items="gastos"
        :items-per-page="1000"
        single-expand
        :expanded.sync="expanded"
        dense
        :hide-default-footer=true
        :show-expand=false
        :loading = loadingTable
        class="elevation-1"
    >
    	<template v-slot:top>
    		<v-toolbar flat>Gastos</v-toolbar>
    	</template>
    	
    	<template v-slot:footer>
    		<v-row>
    		<v-col class="col-md-10 text-right">
    			<strong> Total:</strong>
    		</v-col>
		    <v-col class="col-md-2 text-right pr-7">
		    	<strong>{{totalGasto.toFixed(2)}}</strong>
		    </v-col>
		    
		    
		    </v-row> 
		</template>
    </v-data-table> 
    
    </v-col>
    </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "EstadoResultadosComponent",
  data: () => ({
    expanded: [],
    gastos:[],
    ingresos:[],
    options: {},
    dates: [],
    periodos: [],
    //accountingMonths:[],
    //accountingAccounts:[],
    headers: [
      { text: "Codigo cuenta", value: "codigo_cuenta" },
      { text: "Nombre cuenta", value: "nombre_cuenta" },
      { text: "Saldo", value: "monto", align:"right" },
    ],
    required:[
		v  => !!v || 'El cmapo es requerido'
	],
    requiredSearch:[
        v => !!v || 'El campo es requerido'
    ],
    hostname: window.location.protocol+'//'+window.location.hostname,
    partyInternalOrganization:'',
    
    singleExpand: false,
    
    periodo:'',
    
  }),
  computed: {
		...mapState('master',['loadingTable','url','months','urlExternal']),

		dateRangeText () {
            //console.log(this.dates)
			return this.dates.join(' ~ ')
        },
        totalGasto() {
        	  
        	  let total = 0
        	  const sum = this.gastos.reduce((accumulator, currentValue) => {
        	    return (total += +currentValue['monto'])
        	  }, 0)
        	  return sum
        	},
        	
        	totalIngreso() {
          	 
          	  let total = 0
          	  const sum = this.ingresos.reduce((accumulator, currentValue) => {
          	    return (total += +currentValue['monto'])
          	  }, 0)
          	  return sum
          	},
    },
    methods:{
        ...mapMutations('master',['setUrl','setLoadingTable','setLoadingBtn','setTitleToolbar']),

		...mapActions('master',['errorRequest','requestApi','alertNotification']),

        generarReporte(){

            this.setUrl('estado-resultados')
            this.setLoadingTable(true)
            this.requestApi({
				method: 'POST',
				data :{
					desde: this.dates[0],
					hasta: this.dates[1],
					periodo: this.periodo
				}
			}).then(res =>{
                this.gastos  = res.data.lista.gastos
                this.ingresos = res.data.lista.ingresos
                
                this.dates = [res.data.lista.fecha_desde, res.data.lista.fecha_hasta]
                
                
                this.setLoadingTable(false)
                
			}).catch(()=>{

            }).then(()=>{
                this.setLoadingTable(false)
            })
        },

        
        cargarPeriodos(){
        	let anio = new Date().getFullYear();
            this.setUrl('periodo-contable/'+anio+'/meses-anio')
            //this.setLoadingTable(true)
            this.requestApi({
				method: 'GET',
				data :{
					
				}
			}).then(res =>{
                
                this.periodos = res.data.detail
                
			}).catch(()=>{

            }).then(()=>{
                
            })
        },

        descargarReporte(){
            this.setUrl('estado-resultados')
            this.setLoadingTable(true)
            this.requestApi({
				method: 'POST',
				data :{
					desde: this.dates[0],
					hasta: this.dates[1],
					periodo: this.periodo,
                    exportar: 'true'
                }
			}).then((res) => {
                console.log(res)
                this.setLoadingTable(false)
                let link = document.createElement("a");
                
                link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
            	link.download = res.data.archivo;
                link.click();
            }).catch((err) => {
                
            }).then(()=>{
                this.overlay = false;
                this.setLoadingTable(false)
            });

        }

    },
    
    mounted(){
        //this.generarReporte()
        
        this.cargarPeriodos()
        this.setTitleToolbar('ESTADO DE RESULTADOS')
    }
};
</script>